import * as React from 'react';
import { cn } from '@/utils/utils';
import Logo from '@/public/assets/logo.svg';
import { Typography } from '@/components/Typography';

enum DesktopHeaderVariant {
  Transparent = 'Transparent',
  Black = 'Black',
  Yellow = 'Yellow'
}

interface Props {
  variant?: DesktopHeaderVariant;
  guest?: boolean;
}

const DesktopHeader: React.FC<Props> = ({ variant = DesktopHeaderVariant.Black, guest = true }) => {
  return (
    <header
      className={cn('h-[5.5rem]', 'w-full', 'py-2.5', 'px-10', 'flex', 'justify-between', 'items-center', {
        'bg-transparent': variant == DesktopHeaderVariant.Transparent,
        'bg-common-black': variant == DesktopHeaderVariant.Black,
        'bg-common-yellow-100': variant == DesktopHeaderVariant.Yellow
      })}
    >
      <a href="https://www.aneo.com/tjenester/mobility">
        <Logo
          className={cn('fill-yellow-100', {
            'fill-common-black': variant == DesktopHeaderVariant.Transparent || variant == DesktopHeaderVariant.Yellow,
            'fill-yellow-100': variant == DesktopHeaderVariant.Black
          })}
        />
      </a>
      <Typography
        type="L-B"
        component="a"
        href={guest ? '/login' : '/logout'}
        className={cn('underline', {
          'text-common-black': variant == DesktopHeaderVariant.Transparent || variant == DesktopHeaderVariant.Yellow,
          'text-yellow-100': variant == DesktopHeaderVariant.Black
        })}
      >
        {guest ? 'Login' : 'Logout'}
      </Typography>
    </header>
  );
};

export { DesktopHeader, DesktopHeaderVariant };
