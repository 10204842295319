/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Consents } from '../models/Consents';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ConsentService {

    /**
     * @returns Consents Success
     * @throws ApiError
     */
    public static getCustomerConsents(): CancelablePromise<Consents> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/consent',
        });
    }

    /**
     * @param optinEmail
     * @param optinPhone
     * @returns any Success
     * @throws ApiError
     */
    public static writeCustomerConsent(
        optinEmail: boolean,
        optinPhone: boolean,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/consent',
            query: {
                'OptinEmail': optinEmail,
                'OptinPhone': optinPhone,
            },
        });
    }

}
