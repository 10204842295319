import { atom } from 'jotai';

interface UserToken {
  email: string;
  exp: number;
  externalId: string;
  iat: number;
  iss: string;
  name: string;
  nameid: string;
  nbf: number;
  role: string;
}

// UserToken => the parsed token, meaning the user is logged in
// null => no token found, meaning the user is not logged in
// undefined => the atom is uninitialized and still needs to be hydrated
export const userAtom = atom<UserToken | undefined | null>(undefined);
