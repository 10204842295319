import React, { ErrorInfo } from 'react';
import { LoggingContextType, withLogger } from '@/utils/logger';
import { ErrorSplash } from '@/components/ErrorSplash';
import locale from '@/public/locales/nb/global.json';

interface Props {
  children?: React.ReactNode;
  logger: LoggingContextType;
}

interface State {
  hasError: boolean;
}

class ErrorBoundaryComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can use your own error logging service here
    //this.props.logger.error(error.toString());
  }

  render() {
    if (this.state.hasError) {
      return (
        <ErrorSplash
          header={locale.errors.boundary.header}
          body={locale.errors.boundary.body}
          buttonText={locale.errors.boundary.button}
        />
      );
    }

    return this.props.children;
  }
}

const ErrorBoundary = withLogger(ErrorBoundaryComponent);

export { ErrorBoundary };
