/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerPowerConsumptionDTO } from '../models/CustomerPowerConsumptionDTO';
import type { DeleteDuplicateTransactionsCommand } from '../models/DeleteDuplicateTransactionsCommand';
import type { GetPowerConsumptionStatisticsQuery } from '../models/GetPowerConsumptionStatisticsQuery';
import type { InsertManualTransactionCommand } from '../models/InsertManualTransactionCommand';
import type { ManuallyRecalculateCustomerPowerPackagesCommand } from '../models/ManuallyRecalculateCustomerPowerPackagesCommand';
import type { PowerConsumptionOrder } from '../models/PowerConsumptionOrder';
import type { SendPowerConsumptionStatisticToEmailCommand } from '../models/SendPowerConsumptionStatisticToEmailCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PowerConsumptionService {

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static insertManualTransaction(
        requestBody?: InsertManualTransactionCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/powerconsumption/insert-manual-transaction',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static recalculateCustomerPowerPackages(
        requestBody?: ManuallyRecalculateCustomerPowerPackagesCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/powerconsumption/recalculate-customer-power-packages',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static deleteDuplicateTransactions(
        requestBody?: DeleteDuplicateTransactionsCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/powerconsumption/delete-duplicate-transactions',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns PowerConsumptionOrder Success
     * @throws ApiError
     */
    public static getPowerConsumptionStatistic(
        requestBody?: GetPowerConsumptionStatisticsQuery,
    ): CancelablePromise<Array<PowerConsumptionOrder>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/powerconsumption/statistics',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static sendPowerConsumptionStatisticsToEmail(
        requestBody?: SendPowerConsumptionStatisticToEmailCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/powerconsumption/statistics/email',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param subscriptionId
     * @param year
     * @param dayofyear
     * @returns CustomerPowerConsumptionDTO Success
     * @throws ApiError
     */
    public static getPowerConsumptionByDay(
        subscriptionId: string,
        year: number,
        dayofyear: number,
    ): CancelablePromise<CustomerPowerConsumptionDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/powerconsumption/order/{subscriptionId}/day/{year}/{dayofyear}',
            path: {
                'subscriptionId': subscriptionId,
                'year': year,
                'dayofyear': dayofyear,
            },
        });
    }

    /**
     * @param subscriptionId
     * @param startYear
     * @param startDayOfYear
     * @param endYear
     * @param endDayOfYear
     * @returns number Success
     * @throws ApiError
     */
    public static getPowerConsumptionSeries(
        subscriptionId: string,
        startYear?: number,
        startDayOfYear?: number,
        endYear?: number,
        endDayOfYear?: number,
    ): CancelablePromise<Array<number>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/powerconsumption/order/{subscriptionId}/series',
            path: {
                'subscriptionId': subscriptionId,
            },
            query: {
                'startYear': startYear,
                'startDayOfYear': startDayOfYear,
                'endYear': endYear,
                'endDayOfYear': endDayOfYear,
            },
        });
    }

}
