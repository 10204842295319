/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GenerateAndChargeInvoiceCommand } from '../models/GenerateAndChargeInvoiceCommand';
import type { InvoiceDTO } from '../models/InvoiceDTO';
import type { InvoiceDTOPagedResult } from '../models/InvoiceDTOPagedResult';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class InvoiceService {

    /**
     * @param includes
     * @param pageSize
     * @param page
     * @param orderByKey
     * @param isDescending
     * @param query
     * @param filters
     * @returns InvoiceDTOPagedResult Success
     * @throws ApiError
     */
    public static getInvoices(
        includes?: string,
        pageSize?: number,
        page?: number,
        orderByKey?: string,
        isDescending?: boolean,
        query?: string,
        filters?: string,
    ): CancelablePromise<InvoiceDTOPagedResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/invoice/all/paged',
            query: {
                'Includes': includes,
                'PageSize': pageSize,
                'Page': page,
                'OrderByKey': orderByKey,
                'IsDescending': isDescending,
                'Query': query,
                'Filters': filters,
            },
        });
    }

    /**
     * @param invoiceId
     * @returns boolean Success
     * @throws ApiError
     */
    public static cancelInvoice(
        invoiceId: number,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/invoice/cancel/{invoiceId}',
            path: {
                'invoiceId': invoiceId,
            },
        });
    }

    /**
     * @param invoiceId
     * @returns InvoiceDTO Success
     * @throws ApiError
     */
    public static createNewInvoice(
        invoiceId: number,
    ): CancelablePromise<InvoiceDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/invoice/create/{invoiceId}',
            path: {
                'invoiceId': invoiceId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static chargeInvoice(
        requestBody?: GenerateAndChargeInvoiceCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/invoice/charge',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
