/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AttributesSObject } from '../models/AttributesSObject';
import type { GenerateAndStoreMissingPaymentReceiptsCommand } from '../models/GenerateAndStoreMissingPaymentReceiptsCommand';
import type { PaymentDTO } from '../models/PaymentDTO';
import type { PaymentDTOPagedResult } from '../models/PaymentDTOPagedResult';
import type { RegenerateReceiptCommand } from '../models/RegenerateReceiptCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentService {

    /**
     * @param subscriptionId
     * @param includes
     * @param pageSize
     * @param page
     * @param orderByKey
     * @param isDescending
     * @param queryId
     * @param queryOwnerId
     * @param queryIsDeleted
     * @param queryName
     * @param queryRecordTypeId
     * @param queryAmountC
     * @param queryPaidAmountC
     * @param queryPaymentStatusC
     * @param querySubscriptionTypeC
     * @param queryPayExReferenceNumberC
     * @param queryPayeeReferenceC
     * @param queryDueDateC
     * @param queryPaidDateC
     * @param queryPeriodeStartDateC
     * @param queryPeriodeEndDateC
     * @param querySubscriptionC
     * @param queryReceiptUrlC
     * @param queryContractC
     * @param queryFailedReasonC
     * @param queryPaymentTypeC
     * @param queryFailedCountC
     * @param queryCreatedDate
     * @param queryCreatedById
     * @param queryLastModifiedDate
     * @param queryLastModifiedById
     * @param querySystemModstamp
     * @param queryLastActivityDate
     * @param queryLastViewedDate
     * @param queryLastReferencedDate
     * @param queryAttributesType
     * @param queryAttributesUrl
     * @param queryAttributesReferenceId
     * @param queryAttributesCreatedDate
     * @param queryAttributesCreatedById
     * @param queryAttributesLastModifiedDate
     * @param queryAttributesLastModifiedById
     * @param queryAttributesSystemModstamp
     * @param queryAttributesLastActivityDate
     * @param queryAttributesLastViewedDate
     * @param queryAttributesLastReferencedDate
     * @param queryAttributesAttributesType
     * @param queryAttributesAttributesUrl
     * @param queryAttributesAttributesReferenceId
     * @param queryAttributesAttributesCreatedDate
     * @param queryAttributesAttributesCreatedById
     * @param queryAttributesAttributesLastModifiedDate
     * @param queryAttributesAttributesLastModifiedById
     * @param queryAttributesAttributesSystemModstamp
     * @param queryAttributesAttributesLastActivityDate
     * @param queryAttributesAttributesLastViewedDate
     * @param queryAttributesAttributesLastReferencedDate
     * @param queryAttributesAttributesAttributes
     * @param queryAttributesAttributesAdditionalProperties
     * @param queryAttributesAdditionalProperties
     * @param queryAdditionalProperties
     * @param filters
     * @param yearFilter
     * @param monthFilter
     * @returns PaymentDTOPagedResult Success
     * @throws ApiError
     */
    public static getAllPayments(
        subscriptionId: string,
        includes?: string,
        pageSize?: number,
        page?: number,
        orderByKey?: string,
        isDescending?: boolean,
        queryId?: string,
        queryOwnerId?: string,
        queryIsDeleted?: boolean,
        queryName?: string,
        queryRecordTypeId?: string,
        queryAmountC?: number,
        queryPaidAmountC?: number,
        queryPaymentStatusC?: string,
        querySubscriptionTypeC?: string,
        queryPayExReferenceNumberC?: number,
        queryPayeeReferenceC?: string,
        queryDueDateC?: string,
        queryPaidDateC?: string,
        queryPeriodeStartDateC?: string,
        queryPeriodeEndDateC?: string,
        querySubscriptionC?: string,
        queryReceiptUrlC?: string,
        queryContractC?: string,
        queryFailedReasonC?: string,
        queryPaymentTypeC?: string,
        queryFailedCountC?: number,
        queryCreatedDate?: string,
        queryCreatedById?: string,
        queryLastModifiedDate?: string,
        queryLastModifiedById?: string,
        querySystemModstamp?: string,
        queryLastActivityDate?: string,
        queryLastViewedDate?: string,
        queryLastReferencedDate?: string,
        queryAttributesType?: string,
        queryAttributesUrl?: string,
        queryAttributesReferenceId?: string,
        queryAttributesCreatedDate?: string,
        queryAttributesCreatedById?: string,
        queryAttributesLastModifiedDate?: string,
        queryAttributesLastModifiedById?: string,
        queryAttributesSystemModstamp?: string,
        queryAttributesLastActivityDate?: string,
        queryAttributesLastViewedDate?: string,
        queryAttributesLastReferencedDate?: string,
        queryAttributesAttributesType?: string,
        queryAttributesAttributesUrl?: string,
        queryAttributesAttributesReferenceId?: string,
        queryAttributesAttributesCreatedDate?: string,
        queryAttributesAttributesCreatedById?: string,
        queryAttributesAttributesLastModifiedDate?: string,
        queryAttributesAttributesLastModifiedById?: string,
        queryAttributesAttributesSystemModstamp?: string,
        queryAttributesAttributesLastActivityDate?: string,
        queryAttributesAttributesLastViewedDate?: string,
        queryAttributesAttributesLastReferencedDate?: string,
        queryAttributesAttributesAttributes?: AttributesSObject,
        queryAttributesAttributesAdditionalProperties?: Record<string, any>,
        queryAttributesAdditionalProperties?: Record<string, any>,
        queryAdditionalProperties?: Record<string, any>,
        filters?: string,
        yearFilter?: number,
        monthFilter?: number,
    ): CancelablePromise<PaymentDTOPagedResult> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/{subscriptionId}',
            path: {
                'subscriptionId': subscriptionId,
            },
            query: {
                'Includes': includes,
                'PageSize': pageSize,
                'Page': page,
                'OrderByKey': orderByKey,
                'IsDescending': isDescending,
                'Query.Id': queryId,
                'Query.OwnerId': queryOwnerId,
                'Query.IsDeleted': queryIsDeleted,
                'Query.Name': queryName,
                'Query.RecordTypeId': queryRecordTypeId,
                'Query.Amount__c': queryAmountC,
                'Query.Paid_Amount__c': queryPaidAmountC,
                'Query.Payment_Status__c': queryPaymentStatusC,
                'Query.Subscription_Type__c': querySubscriptionTypeC,
                'Query.PayEx_Reference_Number__c': queryPayExReferenceNumberC,
                'Query.PayeeReference__c': queryPayeeReferenceC,
                'Query.Due_Date__c': queryDueDateC,
                'Query.Paid_Date__c': queryPaidDateC,
                'Query.Periode_Start_Date__c': queryPeriodeStartDateC,
                'Query.Periode_End_Date__c': queryPeriodeEndDateC,
                'Query.Subscription__c': querySubscriptionC,
                'Query.Receipt_URL__c': queryReceiptUrlC,
                'Query.Contract__c': queryContractC,
                'Query.Failed_Reason__c': queryFailedReasonC,
                'Query.Payment_Type__c': queryPaymentTypeC,
                'Query.Failed_Count__c': queryFailedCountC,
                'Query.CreatedDate': queryCreatedDate,
                'Query.CreatedById': queryCreatedById,
                'Query.LastModifiedDate': queryLastModifiedDate,
                'Query.LastModifiedById': queryLastModifiedById,
                'Query.SystemModstamp': querySystemModstamp,
                'Query.LastActivityDate': queryLastActivityDate,
                'Query.LastViewedDate': queryLastViewedDate,
                'Query.LastReferencedDate': queryLastReferencedDate,
                'Query.Attributes.Type': queryAttributesType,
                'Query.Attributes.Url': queryAttributesUrl,
                'Query.Attributes.ReferenceId': queryAttributesReferenceId,
                'Query.Attributes.CreatedDate': queryAttributesCreatedDate,
                'Query.Attributes.CreatedById': queryAttributesCreatedById,
                'Query.Attributes.LastModifiedDate': queryAttributesLastModifiedDate,
                'Query.Attributes.LastModifiedById': queryAttributesLastModifiedById,
                'Query.Attributes.SystemModstamp': queryAttributesSystemModstamp,
                'Query.Attributes.LastActivityDate': queryAttributesLastActivityDate,
                'Query.Attributes.LastViewedDate': queryAttributesLastViewedDate,
                'Query.Attributes.LastReferencedDate': queryAttributesLastReferencedDate,
                'Query.Attributes.Attributes.Type': queryAttributesAttributesType,
                'Query.Attributes.Attributes.Url': queryAttributesAttributesUrl,
                'Query.Attributes.Attributes.ReferenceId': queryAttributesAttributesReferenceId,
                'Query.Attributes.Attributes.CreatedDate': queryAttributesAttributesCreatedDate,
                'Query.Attributes.Attributes.CreatedById': queryAttributesAttributesCreatedById,
                'Query.Attributes.Attributes.LastModifiedDate': queryAttributesAttributesLastModifiedDate,
                'Query.Attributes.Attributes.LastModifiedById': queryAttributesAttributesLastModifiedById,
                'Query.Attributes.Attributes.SystemModstamp': queryAttributesAttributesSystemModstamp,
                'Query.Attributes.Attributes.LastActivityDate': queryAttributesAttributesLastActivityDate,
                'Query.Attributes.Attributes.LastViewedDate': queryAttributesAttributesLastViewedDate,
                'Query.Attributes.Attributes.LastReferencedDate': queryAttributesAttributesLastReferencedDate,
                'Query.Attributes.Attributes.Attributes': queryAttributesAttributesAttributes,
                'Query.Attributes.Attributes.AdditionalProperties': queryAttributesAttributesAdditionalProperties,
                'Query.Attributes.AdditionalProperties': queryAttributesAdditionalProperties,
                'Query.AdditionalProperties': queryAdditionalProperties,
                'Filters': filters,
                'yearFilter': yearFilter,
                'monthFilter': monthFilter,
            },
        });
    }

    /**
     * @param subscriptionId
     * @returns PaymentDTO Success
     * @throws ApiError
     */
    public static getFirstAndLastPayment(
        subscriptionId: string,
    ): CancelablePromise<Array<PaymentDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/first-and-last/{subscriptionId}',
            path: {
                'subscriptionId': subscriptionId,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static regenerateReceipt(
        requestBody: RegenerateReceiptCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/regenerate-receipt',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param paymentNumber
     * @returns any Success
     * @throws ApiError
     */
    public static generateReceipt(
        paymentNumber: number,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/generate-receipt/{paymentNumber}',
            path: {
                'paymentNumber': paymentNumber,
            },
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static generateMissingReceipts(
        requestBody: GenerateAndStoreMissingPaymentReceiptsCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/generate-missing-receipts',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
