import React from 'react';
import { cn } from '@/utils/utils';
import * as Label from '@radix-ui/react-label';
import Link from 'next/link';

type ComponentType =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'h5'
  | 'h6'
  | 'p'
  | 'span'
  | 'sub'
  | 'i'
  | 'u'
  | 'b'
  | 'em'
  | 'strong'
  | 'div'
  | 'label'
  | 'a';

interface ComponentProps {
  Type: ComponentType | typeof Label.Root | typeof Link;
  children: React.ReactNode;
  className: string;
  ariaHidden?: boolean;
  [x: string]: any;
}

const Component: React.FC<ComponentProps> = ({ children, Type, className, ariaHidden, ...rest }) => {
  switch (Type) {
    case 'h1': {
      return (
        <h1 className={className} aria-hidden={ariaHidden}>
          {children}
        </h1>
      );
    }
    case 'h2': {
      return (
        <h2 className={className} aria-hidden={ariaHidden}>
          {children}
        </h2>
      );
    }
    case 'h3': {
      return (
        <h3 className={className} aria-hidden={ariaHidden}>
          {children}
        </h3>
      );
    }
    case 'h4': {
      return (
        <h4 className={className} aria-hidden={ariaHidden}>
          {children}
        </h4>
      );
    }
    case 'h5': {
      return (
        <h5 className={className} aria-hidden={ariaHidden}>
          {children}
        </h5>
      );
    }
    case 'p': {
      return (
        <p className={className} aria-hidden={ariaHidden}>
          {children}
        </p>
      );
    }
    case 'span': {
      return (
        <span className={className} aria-hidden={ariaHidden}>
          {children}
        </span>
      );
    }
    case 'sub': {
      return (
        <sub className={className} aria-hidden={ariaHidden}>
          {children}
        </sub>
      );
    }
    case 'u': {
      return (
        <u className={className} aria-hidden={ariaHidden}>
          {children}
        </u>
      );
    }
    case 'i': {
      return (
        <i className={className} aria-hidden={ariaHidden}>
          {children}
        </i>
      );
    }
    case 'b': {
      return (
        <b className={className} aria-hidden={ariaHidden}>
          {children}
        </b>
      );
    }
    case 'em': {
      return (
        <em className={className} aria-hidden={ariaHidden}>
          {children}
        </em>
      );
    }
    case 'strong': {
      return (
        <strong className={className} aria-hidden={ariaHidden}>
          {children}
        </strong>
      );
    }
    case 'div': {
      return (
        <div className={className} aria-hidden={ariaHidden}>
          {children}
        </div>
      );
    }
    case 'label': {
      return (
        <label className={className} aria-hidden={ariaHidden} {...rest}>
          {children}
        </label>
      );
    }
    case 'a': {
      return (
        <a className={className} aria-hidden={ariaHidden} {...rest}>
          {children}
        </a>
      );
    }
    default: {
      return (
        // @ts-ignore
        <Type className={className} aria-hidden={ariaHidden} {...rest}>
          {children}
        </Type>
      );
    }
  }
};

type TypographyHeaderType = 'XXXL-H' | 'XXL-H' | 'XL-H' | 'L-H' | 'M-H' | 'S-H' | 'XS-H' | 'XXS-H';
type TypographyBodyType = 'XXXL-B' | 'XXL-B' | 'XL-B' | 'L-B' | 'M-B' | 'S-B' | 'XS-B';
type TypographyDetailType = 'XL-D' | 'L-D' | 'M-D' | 'S-D';

interface Props {
  children: React.ReactNode;
  component?: ComponentType | typeof Label.Root | typeof Link;
  type?: TypographyHeaderType | TypographyBodyType | TypographyDetailType;
  weight?: 'light' | 'regular' | 'bold';
  mobileWeight?: 'light' | 'regular' | 'bold';
  style?: 'normal' | 'italic' | 'italic-reverse';
  className?: string;
  [x: string]: any;
}

const Typography: React.FC<Props> = ({
  children,
  component = 'p',
  type,
  weight = 'regular',
  mobileWeight,
  style = 'normal',
  className,
  ...rest
}) => {
  return (
    <Component
      Type={component}
      className={cn(
        // font weight
        {
          'font-light': weight === 'light',
          'font-regular': weight === 'regular',
          'font-heavy': weight === 'bold',
          'md:font-medium': weight === 'bold' || mobileWeight === 'bold',
          'md:font-regular': mobileWeight === 'regular',
          'md:font-light': mobileWeight === 'light'
        },
        // font style
        {
          'font-slant-neg30': style === 'italic',
          'font-slant-pos30': style === 'italic-reverse'
        },
        // Font size Headers
        {
          'text-1300-d': type === 'XXXL-H',
          'md:text-1300-m': type === 'XXXL-H'
        },
        {
          'text-1100-d': type === 'XXL-H',
          'md:text-1100-m': type === 'XXL-H'
        },
        {
          'text-900-d': type === 'XL-H',
          'md:text-900-m': type === 'XL-H'
        },
        {
          'text-700-d': type === 'L-H',
          'md:text-700-m': type === 'L-H'
        },
        {
          'text-500-d': type === 'M-H',
          'md:text-500-m': type === 'M-H'
        },
        {
          'text-300-d': type === 'S-H',
          'md:text-300-m': type === 'S-H'
        },
        {
          'text-200-d': type === 'XS-H',
          'md:text-200-m': type === 'XS-H'
        },
        {
          'text-100-d': type === 'XXS-H',
          'md:text-100-m': type === 'XXS-H'
        },
        {
          'text-600-d': type === 'XXXL-B',
          'md:text-600-m': type === 'XXXL-B'
        },
        {
          'text-500-d': type === 'XXL-B',
          'md:text-500-m': type === 'XXL-B'
        },
        {
          'text-400-d': type === 'XL-B',
          'md:text-400-m': type === 'XL-B'
        },
        {
          'text-300-d': type === 'L-B',
          'md:text-300-m': type === 'L-B'
        },
        {
          'text-200-d': type === 'M-B',
          'md:text-200-m': type === 'M-B'
        },
        {
          'text-100-d': type === 'S-B',
          'md:text-100-m': type === 'S-B'
        },
        {
          'text-75-d': type === 'XS-B',
          'md:text-75-m': type === 'XS-B'
        },
        {
          'text-200-d': type === 'XL-D',
          'md:text-200-m': type === 'XL-D'
        },
        {
          'text-100-d': type === 'L-D',
          'md:text-100-m': type === 'L-D'
        },
        {
          'text-75-d': type === 'M-D',
          'md:text-75-m': type === 'M-D'
        },
        {
          'text-50-d': type === 'S-D',
          'md:text-50-m': type === 'S-D'
        },
        className
      )}
      {...rest}
    >
      {children}
    </Component>
  );
};

export { Typography };
