import React, { createContext, useContext, useEffect, useState } from 'react';
import { datadogLogs, StatusType } from '@datadog/browser-logs';

type StringToTuple<S extends any, A extends any[] = []> = S extends `${string}{${string}${infer Rest}`
  ? StringToTuple<Rest, [...A, any]>
  : A;

interface LoggingContextType {
  debug: <S extends string>(string: S, ...values: StringToTuple<S>) => void;
  info: <S extends string>(string: S, ...values: StringToTuple<S>) => void;
  warn: <S extends string>(string: S, ...values: StringToTuple<S>) => void;
  error: <S extends string>(string: S, ...values: StringToTuple<S>) => void;
  addContext: (context: object) => {
    warn: <S extends string>(message: S, ...values: StringToTuple<S>) => void;
    debug: <S extends string>(message: S, ...values: StringToTuple<S>) => void;
    error: <S extends string>(message: S, ...values: StringToTuple<S>) => void;
    info: <S extends string>(message: S, ...values: StringToTuple<S>) => void;
  };
}

enum DatadogEnv {
  Local = 'local',
  Development = 'development',
  Preview = 'preview',
  Test = 'test',
  Production = 'production'
}

const config = {
  clientToken: 'pub5c257120f1ad3198adad2d490ba019ac',
  site: 'datadoghq.eu',
  service: 'portal-v2',
  forwardErrorsToLogs: true,
  sampleRate: 100
};

const LoggerContext = createContext<LoggingContextType>({
  debug: () => () => {},
  info: () => () => {},
  warn: () => () => {},
  error: () => () => {},
  addContext: () => ({
    debug: () => {},
    info: () => {},
    warn: () => {},
    error: () => {}
  })
});

const useLogger = () => useContext(LoggerContext);

function withLogger<T>(Component: React.ComponentType<T & { logger: ReturnType<typeof useLogger> }>) {
  return function WrappedComponent(props: any) {
    const logger = useLogger();
    return <Component {...props} logger={logger} />;
  };
}

const LoggerProvider: React.FC<{
  children: React.ReactNode;
  env: DatadogEnv;
  version: string;
}> = ({ children, env, version }) => {
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    try {
      if (initialized || !env || !version) {
        // eslint-disable-next-line no-console
        console.warn('Error initializing Datadog, already initalized or missing env/versiong', env, version);
        return;
      }

      datadogLogs.init({
        ...config,
        env: env,
        version: version.replace('+', '_'),
        beforeSend: log => {
          const xhrFetchErrorPattern =
            /^(XHR|Fetch) (error) (GET|OPTIONS|POST|PUT|PATCH|DELETE) (https?:?\/\/[\w\-]+(\.[\w\-]+)+[\/\w\-\.\?\=\&]*)/;

          if (log.message.startsWith('console error: Failed to evaluate variable - CJS - Checkform - Kontaktskjema:')) {
            return false;
          } else if (log.message === 'Network Error') {
            return false;
          } else if (log.message === 'XHR error POST https://aneo.piwik.pro/ppms.php') {
            return false;
          } else if (log.message.startsWith('console error: Error: Abort fetching component for route:')) {
            log.status = 'warn';
          } else if (xhrFetchErrorPattern.test(log.message)) {
            log.MessageTemplate = log.message.replace(
              xhrFetchErrorPattern,
              (match, p1: string, p2: string, p3: string, p4: string) => {
                if (
                  !(p4.startsWith('https://mobility.aneo.com') || p4.startsWith('https://portal-v2-nine.vercel.app'))
                ) {
                  log.status = 'warn';
                }
                return `${p1} ${p2} {method} {url}`;
              }
            );
          } else if (log.message.startsWith("ReferenceError: Can't find variable:")) {
            log.MessageTemplate = "ReferenceError: Can't find variable: {variable}";
          }

          if (log.MessageTemplate == undefined) {
            log.MessageTemplate = log.message;
          }
        }
      });

      setInitialized(true);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.warn('Error initializing Datadog', error);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const addContext = (context: object) => {
    return {
      debug: <S extends string>(message: S, ...values: StringToTuple<S>) => _debug(message, context, ...values),
      info: <S extends string>(message: S, ...values: StringToTuple<S>) => _info(message, context, ...values),
      warn: <S extends string>(message: S, ...values: StringToTuple<S>) => _warn(message, context, ...values),
      error: <S extends string>(message: S, ...values: StringToTuple<S>) => _error(message, context, ...values)
    };
  };

  const debug = <S extends string>(message: S, ...values: StringToTuple<S>) => {
    _debug(message, undefined, ...values);
  };

  const _debug = <S extends string>(message: S, context?: object, ...values: StringToTuple<S>) => {
    const [interpolatedMessage, interpolatedContext] = _(message, values);

    log(
      interpolatedMessage,
      {
        ...context,
        ...interpolatedContext,
        MessageTemplate: message
      },
      'debug'
    );
  };

  const info = <S extends string>(message: S, ...values: StringToTuple<S>) => {
    _info(message, undefined, ...values);
  };

  const _info = <S extends string>(message: S, context?: object, ...values: StringToTuple<S>) => {
    const [interpolatedMessage, interpolatedContext] = _(message, values);

    log(
      interpolatedMessage,
      {
        ...context,
        ...interpolatedContext,
        MessageTemplate: message
      },
      'info'
    );
  };

  const warn = <S extends string>(message: S, ...values: StringToTuple<S>) => {
    _warn(message, undefined, ...values);
  };

  const _warn = <S extends string>(message: S, context?: object, ...values: StringToTuple<S>) => {
    const [interpolatedMessage, interpolatedContext] = _(message, values);

    log(
      interpolatedMessage,
      {
        ...context,
        ...interpolatedContext,
        MessageTemplate: message
      },
      'warn'
    );
  };

  const error = <S extends string>(message: S, ...values: StringToTuple<S>) => {
    _error(message, undefined, ...values);
  };

  const _error = <S extends string>(message: S, context?: object, ...values: StringToTuple<S>) => {
    const [interpolatedMessage, interpolatedContext] = _(message, values);

    log(
      interpolatedMessage,
      {
        ...context,
        ...interpolatedContext,
        MessageTemplate: message
      },
      'error'
    );
  };

  const _: (str: string, values: any[]) => [string, object] = (str, values) => {
    const context: { [index: string]: any } = {};

    const interpolatedString = str.replace(/\$?\{(.+?)\}/g, (match, p1) => {
      const value = values.shift();
      context[p1] = value;
      return value;
    });

    return [interpolatedString, context];
  };

  const log = (message: string, context?: object, type?: StatusType) => {
    if (initialized) {
      try {
        datadogLogs.logger.log(message, context, type);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.warn('Error logging to Datadog', error);
      }
    }
  };

  return <LoggerContext.Provider value={{ debug, info, warn, error, addContext }}>{children}</LoggerContext.Provider>;
};

export { DatadogEnv, withLogger, useLogger, LoggerProvider };
export type { LoggingContextType };
