/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChargingFacilityDTO } from '../models/ChargingFacilityDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChargingFacilityService {

    /**
     * @param id
     * @returns ChargingFacilityDTO Success
     * @throws ApiError
     */
    public static getChargingFacilitiesByHousingCompanyId(
        id: string,
    ): CancelablePromise<Array<ChargingFacilityDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chargingfacility/housing-company/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns ChargingFacilityDTO Success
     * @throws ApiError
     */
    public static getChargingFacilityById(
        id: string,
    ): CancelablePromise<ChargingFacilityDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chargingfacility/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @returns ChargingFacilityDTO Success
     * @throws ApiError
     */
    public static getUserChargingFacility(): CancelablePromise<ChargingFacilityDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chargingfacility/user/logged',
        });
    }

}
