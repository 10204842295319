import * as React from 'react';
import { cn } from '@/utils/utils';
import { Button, ButtonSize, ButtonVariant } from '@/components/Button';
import { Typography } from '@/components/Typography';
import Illustration from '@/public/assets/illustrations/canceled.svg';
import { DesktopHeader, DesktopHeaderVariant } from '@/components/header/DesktopHeader';
import tailwindConfig from '@/tailwind.config';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

interface Props {
  header: string;
  body: string;
  buttonText?: string;
}

const ErrorSplash: React.FC<Props> = ({ header, body, buttonText }) => {
  // @ts-ignore
  const { screens } = tailwindConfig.theme;
  const [isSmall, setIsSmall] = useState<boolean>();
  const isSmallQuery = useMediaQuery({
    query: `only screen and (max-width: ${screens.md.max})`
  });

  useEffect(() => {
    setIsSmall(isSmallQuery);
  }, [isSmallQuery]);

  if (isSmall == undefined) {
    return <></>;
  }

  return (
    <div className={cn('w-full', 'h-screen', 'flex', 'flex-col')}>
      {!isSmall && <DesktopHeader variant={DesktopHeaderVariant.Transparent} />}
      <div className={cn('p-6', 'w-full', 'h-full', 'flex', 'flex-col', 'gap-10', 'justify-center', 'items-center')}>
        <Illustration className={cn('h-[230px]', 'md:h-[150px]')} />
        <div className={cn('max-w-[530px]', 'flex', 'flex-col', 'gap-10', 'items-center')}>
          <Typography type="L-H" component="h1" className={cn('text-center')}>
            {header}
          </Typography>
          <Typography type="L-B" component="p" className={cn('text-center')}>
            {body}
          </Typography>
        </div>
        {buttonText && (
          <Button
            className={cn('w-[327px]', 'md:w-full')}
            buttonVariant={ButtonVariant.Contained}
            buttonSize={ButtonSize.Medium}
            buttonSizeMobile={ButtonSize.Large}
            href={typeof window !== undefined ? location.origin : '/'}
          >
            {buttonText}
          </Button>
        )}
      </div>
    </div>
  );
};

export { ErrorSplash };
