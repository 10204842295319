let screenSizes = {
  '2xl': 1535,
  xl: 1279,
  lg: 1023,
  md: 767,
  sm: 639,
  xs: 511
};

/** @type {import('tailwindcss').Config} */
module.exports = {
  darkMode: 'class',
  content: [
    './pages/**/*.{js,ts,jsx,tsx}',
    './components/**/*.{js,ts,jsx,tsx}',
    './layouts/**/*.{js,ts,jsx,tsx}',
    './utils/**/*.{js,ts,jsx,tsx}'
  ],
  theme: {
    screens: {
      '2xl': { max: screenSizes['2xl'] + 'px' },
      // => @media (max-width: 1535px) { ... }

      xl: { max: screenSizes.xl + 'px' },
      // => @media (max-width: 1279px) { ... }

      lg: { max: screenSizes.lg + 'px' },
      // => @media (max-width: 1023px) { ... }

      md: { max: screenSizes.md + 'px' },
      // => @media (max-width: 767px) { ... }

      sm: { max: screenSizes.sm + 'px' },
      // => @media (max-width: 639px) { ... }

      xs: { max: screenSizes.xs + 'px' }
      // => @media (max-width: 511px) { ... }
    },
    colors: {
      common: {
        black: '#000000',
        white: '#ffffff'
      },
      transparent: 'transparent',
      yellow: {
        darkest: '#CC9C20',
        darker: '#E5AF24',
        100: '#FFC328',
        50: '#FFE9B1',
        25: '#FFF7E3'
      },
      orange: {
        100: '#F26C0C',
        50: '#FFD7BA',
        25: '#FFF1E7'
      },
      blue: {
        100: '#3677FF',
        50: '#CEDCFF',
        25: '#EDF2FC'
      },
      green: {
        100: '#5FD48E',
        50: '#B9FAC5',
        25: '#EAF9ED'
      },
      gray: {
        100: '#59564F',
        80: '#9F9B91',
        60: '#D1CDC4',
        40: '#EAE6E0',
        20: '#F2F0EB',
        10: '#F7F6F2'
      },
      system: {
        red: {
          100: '#DA0000',
          20: '#f5d5d0'
        },
        focus: '#00A3FF'
      }
    },
    extend: {
      width: {
        '2xl': screenSizes['2xl'] + 'px',
        xl: screenSizes.xl + 'px',
        lg: screenSizes.lg + 'px',
        md: screenSizes.md + 'px',
        sm: screenSizes.sm + 'px',
        xs: screenSizes.xs + 'px'
      },
      fontWeight: {
        light: '300',
        regular: '400',
        medium: '500',
        heavy: '700'
      },
      fontSize: {
        '1300-d': [
          '4.125rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '1200-d': [
          '3.625rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '1100-d': [
          '3.25rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '1000-d': [
          '2.875rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '900-d': [
          '2.5625rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '800-d': [
          '2.25rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '700-d': [
          '2rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '600-d': [
          '1.8125rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '500-d': [
          '1.625rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '400-d': [
          '1.4375rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '300-d': [
          '1.25rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '200-d': [
          '1.125rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '100-d': [
          '1rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '75-d': [
          '0.875rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '50-d': [
          '0.875rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '1300-m': [
          '4.625rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '1200-m': [
          '4.125rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '1100-m': [
          '3.625rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '1000-m': [
          '3.25rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '900-m': [
          '2.875rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '800-m': [
          '2.5625rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '700-m': [
          '2.25rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '600-m': [
          '2rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '500-m': [
          '1.8125rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '400-m': [
          '1.625rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '300-m': [
          '1.4375rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '200-m': [
          '1.25rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '100-m': [
          '1.125rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '75-m': [
          '1rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ],
        '50-m': [
          '0.875rem',
          {
            fontStyle: 'normal',
            lineHeight: '130%'
          }
        ]
      }
    }
  },
  plugins: []
};
