/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Charger } from '../models/Charger';
import type { ResetChargingStationCommand } from '../models/ResetChargingStationCommand';
import type { SChargingPointDTO } from '../models/SChargingPointDTO';
import type { StartTransactionCommand } from '../models/StartTransactionCommand';
import type { StopTransactionCommand } from '../models/StopTransactionCommand';
import type { ToggleCableLockCommand } from '../models/ToggleCableLockCommand';
import type { ToggleFreeChargingCommand } from '../models/ToggleFreeChargingCommand';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChargingPointService {

    /**
     * @param id 
     * @returns Charger Success
     * @throws ApiError
     */
    public static getChargingPointById(
id: string,
): CancelablePromise<Charger> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chargingpoint/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param subscriptionId 
     * @returns Charger Success
     * @throws ApiError
     */
    public static getChargingPointsBySubscription(
subscriptionId: string,
): CancelablePromise<Array<Charger>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chargingpoint/subscription/{subscriptionId}',
            path: {
                'subscriptionId': subscriptionId,
            },
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static stopTransaction(
requestBody?: StopTransactionCommand,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chargingpoint/transaction/stop',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static startTransaction(
requestBody?: StartTransactionCommand,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chargingpoint/transaction/start',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static resetChargingStation(
requestBody?: ResetChargingStationCommand,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chargingpoint/reset',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static toggleLock(
requestBody?: ToggleCableLockCommand,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chargingpoint/toggle-lock',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody 
     * @returns any Success
     * @throws ApiError
     */
    public static toggleFreeCharging(
requestBody?: ToggleFreeChargingCommand,
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chargingpoint/toggle-free-charging',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param formData 
     * @returns any Success
     * @throws ApiError
     */
    public static uploadChargingPoints(
formData?: {
file?: Blob;
},
): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chargingpoint/import',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * @param id 
     * @returns SChargingPointDTO Success
     * @throws ApiError
     */
    public static getChargersByParkingLotId(
id: string,
): CancelablePromise<Array<SChargingPointDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/chargingpoint/parking-lot/{id}',
            path: {
                'id': id,
            },
        });
    }

}
