/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TurnOverReportDTO } from '../models/TurnOverReportDTO';
import type { TurnOverReportRequest } from '../models/TurnOverReportRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ReportingService {

    /**
     * @param requestBody
     * @returns TurnOverReportDTO Success
     * @throws ApiError
     */
    public static generateTurnoverCsvReport(
        requestBody?: TurnOverReportRequest,
    ): CancelablePromise<TurnOverReportDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/reporting/turnover/csv',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns string Success
     * @throws ApiError
     */
    public static generateTurnoverCsvReportAsFileStream(
        requestBody?: TurnOverReportRequest,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/reporting/turnover/csv/filestream',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
