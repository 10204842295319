import * as React from 'react';
import { cn } from '@/utils/utils';
import { Oval } from 'react-loading-icons';
import Link from 'next/link';

enum ButtonVariant {
  Contained = 'Contained',
  Outline = 'Outline',
  Yellow = 'Yellow'
}

enum ButtonSize {
  Large = 'Large',
  Medium = 'Medium',
  Small = 'Small'
}

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  buttonVariant?: ButtonVariant;
  buttonSize?: ButtonSize;
  buttonSizeMobile?: ButtonSize;
  fullWidth?: boolean;
  className?: string;
  loading?: boolean;
  href?: string;
  externalHref?: string;
}

const ButtonComponent: React.FC<Props> = ({ children, href, externalHref, ...rest }) => {
  if (externalHref != undefined) {
    return (
      <a className={cn(rest.className, 'select-none', { 'pointer-events-none': rest.disabled })} href={externalHref}>
        {children}
      </a>
    );
  }
  if (href != undefined) {
    return (
      <Link className={cn(rest.className, 'select-none', { 'pointer-events-none': rest.disabled })} href={href}>
        {children}
      </Link>
    );
  }

  return <button {...rest}>{children}</button>;
};

const Button: React.FC<Props> = ({
  children,
  buttonVariant = ButtonVariant.Contained,
  buttonSize,
  buttonSizeMobile,
  fullWidth = false,
  className,
  loading = false,
  href,
  externalHref,
  ...rest
}) => {
  return (
    <ButtonComponent
      {...rest}
      href={href}
      externalHref={externalHref}
      className={cn(
        'rounded-lg',
        'text-common-white',
        'border-2',
        'border-solid',
        'focus:outline-4',
        'focus:outline',
        'focus:outline-system-focus',
        {
          'text-[1.25rem]': buttonSize == 'Large' || buttonSize == undefined,
          'text-[1rem]': buttonSize == 'Small' || buttonSize == 'Medium',
          'md:text-[1.25rem]': buttonSizeMobile == 'Large' || buttonSizeMobile == undefined,
          'md:text-[1rem]': buttonSizeMobile == 'Small' || buttonSizeMobile == 'Medium',
          'leading-[130%]': true
        },
        {
          'px-4': true,
          'py-[0.9375rem]': buttonSize == 'Large' || buttonSize == undefined,
          'py-2': buttonSize == 'Medium',
          'py-[0.375rem]': buttonSize == 'Small',
          'md:py-[0.9375rem]': buttonSizeMobile == undefined || buttonSizeMobile == 'Large',
          'md:py-2': buttonSizeMobile == 'Medium',
          'md:py-[0.375rem]': buttonSizeMobile == 'Small'
        },
        {
          'w-full': fullWidth
        },
        {
          'bg-common-black': buttonVariant == 'Contained',
          'border-common-black': buttonVariant == 'Contained',
          'hover:outline-none': buttonVariant == 'Contained' && !rest.disabled,
          'hover:bg-gray-100': buttonVariant == 'Contained' && !rest.disabled,
          'hover:border-gray-100': buttonVariant == 'Contained' && !rest.disabled,
          'active:outline-none': buttonVariant == 'Contained' && !rest.disabled,
          'active:bg-gray-100': buttonVariant == 'Contained' && !rest.disabled,
          'active:border-gray-100': buttonVariant == 'Contained' && !rest.disabled,
          'bg-gray-100': buttonVariant == 'Contained' && rest.disabled,
          'border-gray-100': buttonVariant == 'Contained' && rest.disabled,
          'text-gray-80': buttonVariant == 'Contained' && rest.disabled
        },
        {
          'text-common-black': buttonVariant == 'Outline',
          'bg-transparent': buttonVariant == 'Outline',
          'border-common-black': buttonVariant == 'Outline',
          'hover:outline-none': buttonVariant == 'Outline' && !rest.disabled,
          'hover:bg-common-black': buttonVariant == 'Outline' && !rest.disabled,
          'hover:text-common-white': buttonVariant == 'Outline' && !rest.disabled,
          'active:outline-none': buttonVariant == 'Outline' && !rest.disabled,
          'active:bg-common-black': buttonVariant == 'Outline' && !rest.disabled,
          'active:text-common-white': buttonVariant == 'Outline' && !rest.disabled,
          'focus:bg-blue-50': buttonVariant == 'Outline' && !rest.disabled,
          'bg-gray-80': buttonVariant == 'Outline' && rest.disabled,
          'text-gray-100': buttonVariant == 'Outline' && rest.disabled
        },
        {
          'bg-yellow-100': buttonVariant == 'Yellow',
          'border-yellow-100': buttonVariant == 'Yellow',
          'text-common-black': buttonVariant == 'Yellow',
          'focus:bg-yellow-100': buttonVariant == 'Yellow' && !rest.disabled,
          'focus:border-yellow-100': buttonVariant == 'Yellow' && !rest.disabled,
          'focus:text-common-black': buttonVariant == 'Yellow' && !rest.disabled,
          'hover:bg-yellow-darker': buttonVariant == 'Yellow' && !rest.disabled,
          'hover:border-yellow-darker': buttonVariant == 'Yellow' && !rest.disabled,
          'hover:text-common-black': buttonVariant == 'Yellow' && !rest.disabled,
          'active:outline-none': buttonVariant == 'Yellow' && !rest.disabled,
          'active:bg-yellow-darkest': buttonVariant == 'Yellow' && !rest.disabled,
          'active:border-yellow-darkest': buttonVariant == 'Yellow' && !rest.disabled,
          'border-gray-100': buttonVariant == 'Yellow' && rest.disabled,
          'text-gray-80': buttonVariant == 'Yellow' && rest.disabled,
          'bg-gray-100': buttonVariant == 'Yellow' && rest.disabled
        },
        'flex',
        'items-center',
        'justify-center',
        className
      )}
    >
      {loading && <Oval height="1rem" className={cn('absolute')} />}
      <span aria-hidden={loading} className={cn({ invisible: loading })}>
        {children}
      </span>
    </ButtonComponent>
  );
};

export { Button, ButtonVariant, ButtonSize };
