/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthenticationRequest } from '../models/AuthenticationRequest';
import type { AuthenticationResponse } from '../models/AuthenticationResponse';
import type { ChangeEmailRequest } from '../models/ChangeEmailRequest';
import type { CreateAdminAccountCommand } from '../models/CreateAdminAccountCommand';
import type { DeleteAdminAccountCommand } from '../models/DeleteAdminAccountCommand';
import type { RefreshTokenRequest } from '../models/RefreshTokenRequest';
import type { ResetPasswordRequest } from '../models/ResetPasswordRequest';
import type { TokenInfo } from '../models/TokenInfo';
import type { User } from '../models/User';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AccountService {

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static confirmEmail(
        requestBody?: ResetPasswordRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/confirm',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static resetPassword(
        requestBody?: ResetPasswordRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/reset-password-request',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns AuthenticationResponse Success
     * @throws ApiError
     */
    public static authenticate(
        requestBody?: AuthenticationRequest,
    ): CancelablePromise<AuthenticationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/authenticate',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param requestBody
     * @returns TokenInfo Success
     * @throws ApiError
     */
    public static refreshToken(
        requestBody?: RefreshTokenRequest,
    ): CancelablePromise<TokenInfo> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/token/refresh',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param loginAsExternalId
     * @returns AuthenticationResponse Success
     * @throws ApiError
     */
    public static loginAs(
        loginAsExternalId?: string,
    ): CancelablePromise<AuthenticationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/login-as',
            query: {
                'loginAsExternalId': loginAsExternalId,
            },
        });
    }

    /**
     * @returns AuthenticationResponse Success
     * @throws ApiError
     */
    public static returnToAdminAccount(): CancelablePromise<AuthenticationResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/return-to-admin-account',
        });
    }

    /**
     * @param email
     * @returns boolean Success
     * @throws ApiError
     */
    public static isEmailAvailable(
        email?: string,
    ): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/email-available',
            query: {
                'email': email,
            },
        });
    }

    /**
     * @param email
     * @returns any Success
     * @throws ApiError
     */
    public static sendResetPasswordRequest(
        email?: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/account/reset-password',
            query: {
                'email': email,
            },
        });
    }

    /**
     * @returns any Success
     * @throws ApiError
     */
    public static authorizedEndpoint(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/account/authorized-endpoint',
        });
    }

    /**
     * @param requestBody
     * @returns User Success
     * @throws ApiError
     */
    public static confirmEmailChange(
        requestBody?: ChangeEmailRequest,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/confirm-email',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @returns string Success
     * @throws ApiError
     */
    public static changeCreditCard(): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/account/credit-card-change',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static createAdminAccount(
        requestBody: CreateAdminAccountCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/account/admin',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

    /**
     * @param requestBody
     * @returns any Success
     * @throws ApiError
     */
    public static deleteAdminAccount(
        requestBody: DeleteAdminAccountCommand,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/account/admin',
            body: requestBody,
            mediaType: 'application/json-patch+json',
        });
    }

}
