/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ProductPricingDTO } from '../models/ProductPricingDTO';
import type { SubscriptionDTOV2 } from '../models/SubscriptionDTOV2';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class SubscriptionService {

    /**
     * @param facilityId
     * @param drivingLength
     * @param chargingPatternValue
     * @param car
     * @returns ProductPricingDTO Success
     * @throws ApiError
     */
    public static calculateFacilitySuggestedSubscriptionLevel(
        facilityId?: string,
        drivingLength?: number,
        chargingPatternValue?: number,
        car?: string,
    ): CancelablePromise<ProductPricingDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/subscription/calculate-facility-suggested-level',
            query: {
                'FacilityId': facilityId,
                'DrivingLength': drivingLength,
                'ChargingPatternValue': chargingPatternValue,
                'Car': car,
            },
        });
    }

    /**
     * @param pricingId
     * @param drivingLength
     * @param chargingPatternValue
     * @param car
     * @returns ProductPricingDTO Success
     * @throws ApiError
     */
    public static calculatePricingSuggestedSubscriptionLevel(
        pricingId?: string,
        drivingLength?: number,
        chargingPatternValue?: number,
        car?: string,
    ): CancelablePromise<ProductPricingDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/subscription/calculate-pricing-suggested-level',
            query: {
                'PricingId': pricingId,
                'DrivingLength': drivingLength,
                'ChargingPatternValue': chargingPatternValue,
                'Car': car,
            },
        });
    }

    /**
     * @param id
     * @returns SubscriptionDTOV2 Success
     * @throws ApiError
     */
    public static getDetailedSubscription(
        id: string,
    ): CancelablePromise<SubscriptionDTOV2> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/subscription/{id}/detailed',
            path: {
                'id': id,
            },
        });
    }

    /**
     * @param id
     * @returns SubscriptionDTOV2 Success
     * @throws ApiError
     */
    public static getSubscriptionById(
        id: string,
    ): CancelablePromise<SubscriptionDTOV2> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/subscription/{id}',
            path: {
                'id': id,
            },
        });
    }

}
