import { ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

import { EstablishmentTypeEnum } from '@/utils/api/services/openapi';
import { LoggingContextType } from '@/utils/logger';
import { ChargingSupplier } from './constants/ChargingSupplier';

const customTwMerge = extendTailwindMerge({
  classGroups: {
    'font-weight': [
      {
        font: ['light', 'regular', 'medium', 'heavy']
      }
    ],
    'font-size': [
      {
        text: [
          '1300-d',
          '1200-d',
          '1100-d',
          '1000-d',
          '900-d',
          '800-d',
          '700-d',
          '600-d',
          '500-d',
          '400-d',
          '300-d',
          '200-d',
          '100-d',
          '75-d',
          '50-d',
          '1300-m',
          '1200-m',
          '1100-m',
          '1000-m',
          '900-m',
          '800-m',
          '700-m',
          '600-m',
          '500-m',
          '400-m',
          '300-m',
          '200-m',
          '100-m',
          '75-m',
          '50-m'
        ]
      }
    ]
  }
});

export function cn(...inputs: ClassValue[]) {
  return customTwMerge(clsx(inputs));
}

export function campaignIdToEstablishmentType(campaignId?: string | null, logger?: LoggingContextType) {
  switch (campaignId) {
    // Re-etablering
    case process.env.NEXT_PUBLIC_SF_ID_CAMPAIGN_REESTABLISHMENT: {
      return EstablishmentTypeEnum._3;
    }
    //Ohmia Now
    case process.env.NEXT_PUBLIC_SF_ID_CAMPAIGN_NOW: {
      return EstablishmentTypeEnum._1;
    }
    // Ohmia Ready
    case process.env.NEXT_PUBLIC_SF_ID_CAMPAIGN_READY: {
      return EstablishmentTypeEnum._0;
    }
    default: {
      if (campaignId != undefined) {
        logger?.error('Tried to get establishment type from ID ${Campaign} that isnt mapped', campaignId);
      } else {
        logger?.warn('Tried to get establishment type from undefined');
      }
      return undefined;
    }
  }
}

export const isChargingSupplierWithBackplate = (supplier: ChargingSupplier | string | null | undefined) =>
  supplier === ChargingSupplier.Easee || supplier === ChargingSupplier.Garo;
