/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export enum SubscriptionLevel {
    '_0' = 0,
    '_6' = 6,
    '_7' = 7,
    '_8' = 8,
    '_9' = 9,
    '_10' = 10,
    '_11' = 11,
    '_12' = 12,
    '_13' = 13,
    '_14' = 14,
    '_15' = 15,
    '_16' = 16,
}
