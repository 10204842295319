import '@/styles/globals.css';
import '@/components/stepper/desktop/stepper.css';
import '@/components/subscriptionPackages/SubscriptionPackagePicker.css';
import '@/components/inputPhone/inputPhone.css';
import '@/components/loader/loader.css';
import 'react-toastify/dist/ReactToastify.css';
import '@/utils/toast/toast.css';
import type { AppContext, AppInitialProps, AppProps } from 'next/app';
import NextApp from 'next/app';
import React, { useEffect } from 'react';
import { NextComponentType, NextPage, NextPageContext } from 'next';
import { OpenAPI } from '@/utils/api/services/openapi';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { Provider } from 'jotai';
import { ToastContainer } from '@/utils/toast/toast';
import { LoggerProvider } from '@/utils/logger';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { VERSION } from '@/datadogVersion';
import qs from 'querystring';
import jwt_decode from 'jwt-decode';
import { useHydrateAtoms } from 'jotai/react/utils';
import { userAtom } from '@/state/user';
import { PiwikProvider } from '@/components/PiwikProvider';
import Script from 'next/script';
import TagManager from 'react-gtm-module';
import absoluteUrl from 'next-absolute-url';
import Head from 'next/head';

type GetLayout = (page: React.ReactNode) => React.ReactNode;

type Page<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: GetLayout;
};

export type { Page };

type MyAppProps<P = {}> = AppProps<P> & {
  Component: Page<P>;
  user?: UserToken;
  apiPath: string;
};

const defaultGetLayout: GetLayout = (page: React.ReactNode): React.ReactNode => page;

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 5 * (60 * 1000),
      cacheTime: 10 * (60 * 1000)
    }
  }
});

const Page: React.FC<{ user?: UserToken; Component: NextComponentType<NextPageContext, any, any> & Page<{}, {}> }> = ({
  user,
  Component,
  ...rest
}) => {
  useHydrateAtoms(new Map([[userAtom, user]]));
  return <Component {...rest} />;
};

function App({ Component, pageProps, user, apiPath, router }: MyAppProps): JSX.Element {
  const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
  OpenAPI.BASE = apiPath;

  const getLayout = Component.getLayout ?? defaultGetLayout;

  useEffect(() => {
    if (gtmId) TagManager.initialize({ gtmId });
  }, [gtmId]);

  return (
    <LoggerProvider env={process.env.NEXT_PUBLIC_DATADOG_ENV} version={VERSION}>
      <ErrorBoundary>
        <PiwikProvider>
          <QueryClientProvider client={queryClient}>
            <Provider>
              <Head>
                <title>Aneo Mobility</title>
                <meta charSet="utf-8" />
                <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
                <meta name="description" content="" />
                <meta name="keywords" content="" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="mobile-wep-app-capable" content="yes" />
                <meta name="apple-mobile-wep-app-capable" content="yes" />
                <meta name="apple-mobile-web-app-status-bar-style" content="default" />
                <link rel="icon" href="/v2/favicon/icon.svg" />
                <link rel="apple-touch-icon" sizes="180x180" href="/v2/favicon/apple-touch-icon.png" />
                <link rel="manifest" href="/v2/favicon/manifest.webmanifest" />
              </Head>
              {getLayout(<Page user={user} Component={Component} {...pageProps} />)}
            </Provider>
            <ToastContainer />
            {(process.env.NODE_ENV == 'production' || process.env.NODE_ENV == 'test') && (
              <Script
                src={
                  typeof window !== 'undefined'
                    ? `${window.location.origin}${router.basePath}/scripts/maze.js`
                    : '/scripts/maze.js'
                }
              />
            )}
          </QueryClientProvider>
        </PiwikProvider>
      </ErrorBoundary>
    </LoggerProvider>
  );
}

interface UserToken {
  email: string;
  exp: number;
  externalId: string;
  iat: number;
  iss: string;
  name: string;
  nameid: string;
  nbf: number;
  role: string;
}

type MyAppInitialProps<P = {}> = AppInitialProps<P> & {
  user: UserToken | null;
  apiPath: string;
};

App.getInitialProps = async (context: AppContext): Promise<MyAppInitialProps> => {
  const { origin } = absoluteUrl(context.ctx.req);
  const cookies = qs.decode(context.ctx.req?.headers.cookie ?? '', '; ');
  const access_token = cookies['access_token'];
  let user = null;
  try {
    if (access_token && typeof access_token == 'string') {
      user = jwt_decode<UserToken>(access_token);
    }
  } catch {}

  const ctx = await NextApp.getInitialProps(context);

  return { ...ctx, user: user, apiPath: `${origin}/v2` };
};

export default App;
